<script>
import Loading from '@shell/components/Loading';
import CreateEditView from '@shell/mixins/create-edit-view';
import AuthConfig from '@shell/mixins/auth-config';

import CruResource from '@shell/components/CruResource';
import InfoBox from '@shell/components/InfoBox';
import LabeledInput from '@components/Form/LabeledInput/LabeledInput.vue';
import Banner from '@components/Banner/Banner.vue';
import AllowedPrincipals from '@shell/components/auth/AllowedPrincipals';
import AuthBanner from '@shell/components/auth/AuthBanner';
import CopyToClipboardText from '@shell/components/CopyToClipboardText';

const NAME = 'gig';

export default {
  components: {
    Loading,
    CruResource,
    InfoBox,
    LabeledInput,
    Banner,
    AllowedPrincipals,
    AuthBanner,
    CopyToClipboardText
  },

  mixins: [CreateEditView, AuthConfig],

  computed: {
    tArgs() {
      let hostname = '';

      if (process.client) {
        hostname = window.location.hostname;
      }

      return {
        hostname,
        serverUrl: this.serverUrl,
        provider:  this.displayName,
        username:  this.principal.loginName || this.principal.name,
      };
    },

    NAME() {
      return NAME;
    },

    toSave() {
      return {
        enabled:           true,
        gigOauthConfig: this.model,
        description:       'Enable IAM OAuth',
      };
    }
  },
};
</script>

<template>
  <Loading v-if="$fetchState.pending" />
  <div v-else>
    <CruResource
      :cancel-event="true"
      :done-route="doneRoute"
      :mode="mode"
      :resource="model"
      :subtypes="[]"
      :validation-passed="true"
      :finish-button-mode="model.enabled ? 'edit' : 'enable'"
      :can-yaml="false"
      :errors="errors"
      :show-cancel="showCancel"
      @error="e=>errors = e"
      @finish="save"
      @cancel="cancel"
    >
      <template v-if="model.enabled && !isEnabling && !editConfig">
        <AuthBanner :t-args="tArgs" :disable="disable" :edit="goToEdit">
          <template slot="rows">
            <tr><td>{{ t(`authConfig.${NAME}.domain`) }}: </td><td>{{ model.hostname }}</td></tr>
          </template>
        </AuthBanner>

        <hr />

        <AllowedPrincipals provider="gig" :auth-config="model" :mode="mode" />
      </template>

      <template v-else>
        <Banner v-if="!model.enabled" :label="t('authConfig.stateBanner.disabled', tArgs)" color="warning" />
        <div :style="{'align-items':'center'}" class="row mb-20">
          <div class="col span-5">
            <LabeledInput
              v-model="model.hostname"
              :label="t(`authConfig.${NAME}.domain`)"
              :mode="mode"
              required
            />
          </div>
        </div>
        <InfoBox class=" mt-20 mb-20">
          <h3 v-html="t('authConfig.gig.steps.1.title', tArgs, true)" />
          <ul class="mt-0 step-list">
            <li>{{ t('authConfig.gig.steps.1.body.1', {}, true) }} </li>
            <li>{{ t('authConfig.gig.steps.1.body.2', {}, true) }} </li>
            <li>{{ t('authConfig.gig.steps.1.body.3', {}, true) }} </li>
            <li>{{ t('authConfig.gig.steps.1.body.4', {}, true) }} </li>
            <li>{{ t('authConfig.gig.steps.1.body.5', {}, true) }} </li>
            <li>{{ t('authConfig.gig.steps.1.body.6', {}, true) }} </li>
            <li>{{ t('authConfig.gig.steps.1.body.7', {}, true) }} <CopyToClipboardText :plain="true" :text="serverUrl+'/verify-auth'" /> </li>
          </ul>
        </InfoBox>
        <div :style="{'align-items':'center'}" class="row mb-20">
          <div class="col span-4">
            <LabeledInput
              v-model="model.clientId"
              :label="t(`authConfig.${NAME}.clientId`)"
              :mode="mode"
            />
          </div>
          <div class="col span-4">
            <LabeledInput
              v-model="model.clientSecret"
              type="password"
              :label="t(`authConfig.${NAME}.clientSecret`)"
              :mode="mode"
            />
          </div>
          <div class="col span-4">
            <LabeledInput
              v-model="model.redirectURL"
              :label="t(`authConfig.${NAME}.redirectURL`)"
              :mode="mode"
              :disabled="true"
            />
          </div>
        </div>

        <div v-if="!model.enabled" class="row">
          <div class="col span-12">
            <Banner color="info" v-html="t('authConfig.associatedWarning', tArgs, true)" />
          </div>
        </div>
      </template>
    </CruResource>
  </div>
</template>
<style lang="scss" scoped>
  .step-list li:not(:last-child) {
    margin-bottom: 8px;
  }
</style>
